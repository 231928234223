/* products.css */

#products {
  padding: 40px 0;
}

.products-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.product {
  display: flex;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.image-left {
  flex-direction: row;
}

.image-right {
  flex-direction: row-reverse;
}

.product-image {
  flex: 1;
  max-height: 300px;
  position: relative;
  overflow: hidden;
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.product-image:hover img {
  transform: scale(1.1);
}

.product-description {
  flex: 1;
  padding: 20px;
}

.product-description h3 {
  margin-top: 0;
}

.product-description p {
  margin-bottom: 0;
}

.buy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #17de35;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-width: 150px; 
  width: 100%; 
  box-sizing: border-box; 
}

.buy-button:hover {
  background-color: #f1960e;
}

.buy-button img {
  width: 30px;
  height: auto;
  margin-right: 5px;
}

/* Responsive styles for mobile devices */
@media (max-width: 576px) {
  .product {
    flex-direction: column;
    align-items: center;
  }

  .product-image {
    flex: none;
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    max-height: none;
  }

  .product-description {
    flex: 1;
    padding: 0;
    text-align: center;
  }
}
