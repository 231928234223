.section-contact {
  padding: 80px 0;
}

.contact-image img {
  width: 100%;
  border-radius: 0%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.contact-form {
  background-color: #FFC26F;
  padding: 30px;
  border-radius: 15px;
  border: 1px solid #eee6e6;
  box-shadow: 0 2px 4px rgba(0.3, 0.3, 0.3, 0.3);
}

.contact-form h2 {
  margin-bottom: 20px;
  color: #884A39;
}

.contact-form form {
  margin-top: 20px;
}

.contact-form .form-group label {
  display: block;
  margin-bottom: 5px;
}

.contact-form .form-group input,
.contact-form .form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #d0d5e1;
  box-shadow: 0 2px 4px rgba(0.3, 0.3, 0.3, 0.3);
}

.contact-form .form-group textarea {
  height: 150px;
}

.contact-form button {
  display: block;
  margin-top: 20px;
}
