.footer {
  background-color: #CE8042;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.logo-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logo {
  width: 150px;
  height: auto; /* Adjust the width of the logo as needed */
}

.social-icons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.social-icons a {
  display: inline-block;
  margin-right: 10px;
  color: #f3f7f7;
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #007bff;
}

.footer-text {
  font-size: 14px;
  color: #f7f5f5;
}

/* Media Queries for Responsive Styling */

/* Small devices (landscape phones) */
@media (max-width: 576px) {
  .footer {
    padding: 10px 0;
  }

  .footer-content {
    flex-direction: column;
  }

  .social-icons {
    justify-content: center;
    margin-bottom: 10px;
  }

  .social-icons a {
    font-size: 20px;
  }

  .footer-text {
    font-size: 12px;
  }
}
