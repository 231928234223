/* Home.css */

/* Set the height of the carousel */
#home .container {
  height: 100vh; /* Make the carousel container full-screen */
  padding: 0; /* Remove any padding */
}

/* Make the carousel images full-screen */
#home .carousel-item img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  animation-name: zoomIn;
  animation-duration: 10s; /* Adjust the animation duration as needed */
  animation-timing-function: ease-in-out; /* Adjust the animation timing function as needed */
  animation-iteration-count: infinite; /* Repeat the animation indefinitely */
}

/* Define the zoom-in animation */
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2); /* Adjust the scale value for the zoom effect */
  }
  100% {
    transform: scale(1);
  }
}

/* Customize the carousel caption */
#home .carousel-caption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0.8; /* Add a background color to the caption area */
}

/* Add background to the carousel caption texts */
#home .carousel-caption h3,
#home .carousel-caption p {
  background-color: #f0eeee;
  /* Add a background color with transparency to the text area */
  border-radius: 10px; /* Add a border radius to shape the background */
  padding: 10px; /* Add padding for spacing */
  margin: 0; /* Remove any margin */
  color: #0c0b09;
  /* font-weight: bold; */
  font-family: Garamond, sans-serif;
  font-size: xx-large;
}

/* Adjust the font sizes */
#home .carousel-caption h3 {
  font-size: 24px; /* Adjust the font size as needed */
}

#home .carousel-caption p {
  font-size: 18px; /* Adjust the font size as needed */
}

/* Set the overall background color of the home section */
body {
  overflow-x: hidden;
}

/* Responsive styles for mobile devices */
@media (max-width: 576px) {
  /* Set the height of the carousel for mobile devices */
  #home .container {
    height: 60vh;
  }

  /* Adjust the font sizes for mobile devices */
  #home .carousel-caption h3 {
    font-size: 20px;
  }

  #home .carousel-caption p {
    font-size: 16px;
  }
}
